import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bulma/css/bulma.min.css';
import './App.css';
import Navbar from './Navbar';
// import { AuthProvider } from './auth/AuthContext.js';
import AnswerDrafter from './answer/AnswerDrafter.js';
import ComplaintDrafter from './complaint/ComplaintDrafter.js';
import RfpDrafter from './rfp/RfpDrafter.js';
import RogDrafter from './rog/RogDrafter.js';
import RfaDrafter from './rfa/RfaDrafter.js';
import ObjectionsDrafter from './objections/ObjectionsDrafter.js';
import ElementChecker from './elementchecker/ElementChecker.js';
import Profile from './auth/Profile.js';
// import PR from './auth/PR.js'; // Protected Routes
import LoggedInLanding from './LoggedInLanding.js';
import awsConfig from './amplify-config.js';
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import TokenFetcher from './auth/TokenFetcher.js';

Amplify.configure(awsConfig);

function App() {

  return (
    <>
    <TokenFetcher />
    <Authenticator hideSignUp>
      {({ signOut, user }) => (
        <Router>
          <Navbar handleLogout={signOut} />
          <Routes>
            <Route exact path="/" element={<LoggedInLanding />} />
            <Route path="/AnswerDrafter/:draftId" element={<AnswerDrafter />} />
            <Route path="/ComplaintDrafter/:draftId" element={<ComplaintDrafter />} />
            <Route path="/RfpDrafter/:draftId" element={<RfpDrafter />} />
            <Route path="/RogDrafter/:draftId" element={<RogDrafter />} />
            <Route path="/RfaDrafter/:draftId" element={<RfaDrafter />} />
            <Route path="/ObjectionsDrafter/:draftId" element={<ObjectionsDrafter />} />
            <Route path="/ElementChecker/:draftId" element={<ElementChecker />} />
            <Route path="/profile" element={<Profile />} />
          </Routes>
        </Router>
      )}
    </Authenticator></>
  );

}

export default App;
