// Step2.js

import React, { useState } from 'react';
import '../global.css';

function Step3({onSubmit, advSt}) {
  const [isChecked, setIsChecked] = useState(true);

  const handleToggle = () => {
      setIsChecked(!isChecked);
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className='block'>
          <h3 className="title is-4">Writing the Counterclaim</h3>
        </div>
        <div className='block'>
          <div class="field" style={{marginBottom: '4px'}}>
            <input id="switchRoundedInfo" type="checkbox" name="wants_counterclaim" className="switch is-rounded is-info is-medium" checked={isChecked} onChange={handleToggle}></input>
            <label for="switchRoundedInfo">File a Counterclaim</label>
          </div>
          <label className='label' htmlFor="ccFacts">Are there additional facts that should be considered when drafting the counterclaim?  If so, enter them here.</label>
          <textarea className="textarea" id="ccFacts" name="ccFacts" rows="8" cols="50"></textarea>
        </div>
        <div className="has-text-right">
          <button className="button is-normal is-success" type="submit">Next: Introduction</button>
        </div>
      </form>
      {/* <div className="has-text-right">
        <button className="button is-normal is-success" type="button" onClick={advSt}>Next Step: Introduction</button>
      </div> */}
    </div>
    );
}

export default Step3;

