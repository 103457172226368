// Step0.js

import React, { useState } from 'react';

function Step0({ onSubmit, onChange, fileName }) {

  return (
    <div>
      <h3 className="title is-3">Upload a Request for Production</h3>
      <form onSubmit={onSubmit}>

        <div className='block'>
          <label>Are you representing the plaintiff or defendant?</label>
          <select className="dropdown is-active" id="side" name="side">
            <option value="defendant">defendant</option>
            <option value="plaintiff">plaintiff</option>
          </select>
        </div>

        <div className="file block has-name">
          <label className="file-label">
            <input className="file-input" type="file" name="fileUpload" id="fileUpload" onChange={onChange} />
            <span className="file-cta">
              <span className="file-icon">
                <i className="fas fa-upload"></i>
              </span>
              <span className="file-label">{fileName ? fileName.name : "Choose a file…"}</span>
            </span>
          </label>
        </div>
        <button className="button is-link" type="submit">Upload RFPs</button>
      </form>
    </div>
  );
}

export default Step0;
