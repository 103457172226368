// Step0.js

import React, { useState } from 'react';
import Icon from '@mdi/react';
import { mdiTrayArrowUp } from '@mdi/js';
import '../global.css';

function Step0({ onSubmit, onChange, fileName }) {

  return (
    <div>
      <div className='block' style={{ marginBottom: '8px' }}>
        <h3 className="title is-4">Upload a Complaint</h3>
      </div>
      <form onSubmit={onSubmit}>

        <div className='block'>
          <label className='label'>Are you representing the plaintiff or defendant?</label>
          <select className="dropdown is-active select" id="side" name="side" style={{ maxWidth:'160px'}}>
            <option value="defendant">Defendant</option>
            <option value="plaintiff">Plaintiff</option>
          </select>
        </div>
        <div className='upload-container' style={{ gap: '6px' }}>
          <div>
            <p>Select a file to upload as your complaint.</p>
          </div>

          <div className="file block has-name">
            <label className="file-label">
              <input className="file-input" type="file" name="fileUpload" id="fileUpload" onChange={onChange} />
              <span className="file-cta">
              <span className='icon' style={{ marginRight:'0.25em' }}>
                    <Icon path={mdiTrayArrowUp} size={0.9} />
                  </span>
                <span className="file-label">{fileName ? fileName.name : "Select a file to upload"}</span>
              </span>
            </label>
          </div>
        </div>
        
        <div className='has-text-right'>
          <button className="button is-link" type="submit">Upload Complaint</button>
        </div>
        
      </form>
    </div>
  );
}

export default Step0;
