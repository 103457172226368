// ResponseDrafter.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../Constants.js';
import Step0 from './Step0.js';
import Step1 from './Step1.js';
import Step2 from './Step2.js';
import Step3 from './Step3.js';
import Step4 from './Step4.js';
import Step5 from './Step5.js';
import ModalWithProgressBar from '../ModalWithProgressBar.js';
import { ensureHeaders } from '../auth/useSetHeaders';
import Icon from '@mdi/react';
import { mdiFileDocumentEditOutline, mdiTrayArrowUp, mdiTrayArrowDown } from '@mdi/js';
import '../global.css';


function AnswerDrafter() {
  const [fileName, setFileName] = useState(process.env.PUBLIC_URL + '/CV-126.pdf');
  const [key, setKey] = useState(0);
  const [appState, setAppState] = useState(0); // Initial state
  const [taskId, setTaskId] = useState(null);  // For async
  const [selectedFile, setSelectedFile] = useState(null);
  const [outline, setOutline] = useState(
     [["Jurisdiction", null], ["Paragraph 4", 4], ["Paragraph 5", 5], ["Paragraph 6", 6], ["Factual Allegations", null], ["Paragraph 7", 7]]
  );
  const [ads, setAds] = useState([["Preemption by Federal Law", "An explanation why this is a good cause will appear here"], 
  ["Statute of Limitations", "An explanation why this is a good cause will appear here"]]);
  // const [ccs, setCcs] = useState([["Preemption by Federal Law", "An explanation why this is a good cause will appear here"], 
  // ["Statute of Limitations", "An explanation why this is a good cause will appear here"]]);
  // const [prayer, setPrayer] = useState(["Court enter judgement", "Permanent injunction", "Damages"]);
  const [intro, setIntro] = useState("A proposed introduction will appear here after Counter Claims, Prayers for Relief, and Affirmative Defenses have been selected.");
  const [generalDenial, setGeneralDenial] = useState(null);
  const [defendant, setDefendant] = useState([["Abc", "Xyz"]]);

  // modal 
  const [modalIsActive, setModaIsActive] = useState(false);
  const [modalProgress, setModalProgress] = useState(0);

  let { draftId } = useParams();

  const advSt = () => {
    setAppState(appState + 1);
  }

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    if (selectedFile) {
      formData.append('file', selectedFile);
    }
    if (!selectedFile && appState === 0){
      alert("Please select a file before submitting!");
      return
    }

    // formData.appState = appState;
    setModaIsActive(true);
    setModalProgress(5);
    formData.append('appState', appState)
    formData.append('draftId', parseInt(draftId))

    try {
      await ensureHeaders()
      const response = await axios.post(API_URL + 'answerdrafter', formData);
      // Axios automatically handles the conversion of the response to JSON
      const result = response.data;
      if (response.status === 200) { 
        setTaskId(result.result_id);
        console.log('Task submitted successfully');
      } else {
        console.error('Form submission failed');
      }
    } catch (error) {
      console.error('Form submission failed', error);
    }
  };

  // Function to check the task status
  const checkTaskStatus = async () => {
    try {
      console.log("checking the status");
      const response = await axios.get(`${API_URL}result/${taskId}`);
      if (response.data.ready) {
        setTaskId(null); // Reset taskId

        // this could be streamlined with a map
        if ('ads_suggestions' in response.data.value){
          setAds(response.data.value.ads_suggestions);
        }
        if ('outline' in response.data.value){
          setOutline(response.data.value.outline);
        }
        if ('intro' in response.data.value){
          setIntro(response.data.value.intro);
        }
        if ('general_denial' in response.data.value){
          setGeneralDenial(response.data.value.general_denial);
        }
        if ('defendant' in response.data.value){
          setDefendant(response.data.value.defendant);
          console.log("set defendant: ", response.data.value.defendant);
        }

        setModalProgress(100);
        // update the file to display
        setFileName(response.data.value.file_name);
        setKey(prevKey => prevKey + 1);

        advSt();

        setModaIsActive(false);
      }
    } catch (error) {
      console.error('Error checking task status:', error);
      setTaskId(null);
      setModaIsActive(false);
      alert("There was an error, please contact customer support.");
    }
  };
    
  // Start polling when taskId is set
  useEffect(() => {
    let intervalId;
    if (taskId) {
      intervalId = setInterval(() => {
        checkTaskStatus();
        let delta = appState === 0 ? 7 : 10;
        setModalProgress(modalProgress => modalProgress + delta);
      }, 5000); // Poll every 5000 milliseconds (5 seconds)
    }
    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [taskId]);

    const tabs = [
      { icon: mdiTrayArrowUp, text: 'Upload' },
      { icon: mdiFileDocumentEditOutline, text: 'Answers' },
      { icon: mdiFileDocumentEditOutline, text: 'ADs' },
      { icon: mdiFileDocumentEditOutline, text: 'Counterclaim' },
      { icon: mdiFileDocumentEditOutline, text: 'Intro' },
      { icon: mdiTrayArrowDown, text: 'Finish' }
  ];

  return (
    <div className="App">
        <div className="split left">
            <div className="dropdown-container" style={{margin: '20px 32px'}}>
              <div className="tabs is-toggle is-progress">
                  <ul>
                      {tabs.map((tab, index) => (
                          <li key={index} className={index === appState ? 'is-active' : ''}>
                          <a style={{ padding: '8px', display: 'inline-flex', alignItems:'center' }}>
                          {/* <a> */}
                              <span className="icon is-small">                              
                                <Icon path={tab.icon} size={1} />
                              </span>
                              <span>{tab.text}</span>
                          </a>
                          </li>
                      ))}
                  </ul>
              </div>
                <div>

                    {/* Conditional rendering based on appState */}
                    {appState === 0 && <Step0 onSubmit={handleSubmit} onChange={handleFileChange} fileName={selectedFile}/>}
                    {appState === 1 && <Step1 onSubmit={handleSubmit} generalDenial={generalDenial} inputArray={outline} defendant={defendant} />}
                    {appState === 2 && <Step2 onSubmit={handleSubmit} advSt={advSt} initialAds={ads}/>}
                    {appState === 3 && <Step3 onSubmit={handleSubmit} advSt={advSt} />}
                    {appState === 4 && <Step4 onSubmit={handleSubmit} advSt={advSt} intro={intro}/>}
                    {appState === 5 && <Step5 draftId={draftId}/>}
                </div>
            </div> 
            <ModalWithProgressBar isActive={modalIsActive} progress={modalProgress} />
        </div>
        <div className="split right">
            <embed key={key} src={fileName} width={"100%"} height={2000} />
        </div>
    </div>
  );
}

export default AnswerDrafter;
